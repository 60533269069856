@tailwind base;
@tailwind components;
@tailwind utilities;

.car-anim{
    animation: car 100s ease-in-out infinite alternate;
}
@keyframes car {
    from{
        left: 5%;
    }
    to{
        left: 90%;
    }
}